export class Language {
  id = 0;
  createdDate = "";
  lastModifiedBy = "";
  lastModifiedDate = "";
  isDeleted = 0;
  isPublish = 0;
  canEdit = 0;
  name = "";
  shortcut = "";
  isDefault = 0;

  constructor(d) {
    this.id = d.id;
    this.createdDate = d.createdDate;
    this.lastModifiedBy = d.lastModifiedBy;
    this.lastModifiedDate = d.lastModifiedDate;
    this.isDeleted = d.isDeleted;
    this.isPublish = d.isPublish;
    this.canEdit = d.canEdit;
    this.name = d.name;
    this.shortcut = d.shortcut;
    this.isDefault = d.isDefault;
  }
}

export class Picture {
  id = 0;
  createdBy = "";
  createdDate = "";
  lastModifiedBy = "";
  lastModifiedDate = "";
  isDeleted = 0;
  isPublish = 0;
  canEdit = 0;
  name = "";
  url = "";
  categories = [] | null;
  manufacturers = [] | null;
  products = [] | null;

  constructor(d) {
    this.id = d.id;
    this.createdBy = d.createdBy;
    this.createdDate = d.createdDate;
    this.lastModifiedBy = d.lastModifiedBy;
    this.lastModifiedDate = d.lastModifiedDate;
    this.isDeleted = d.isDeleted;
    this.isPublish = d.isPublish;
    this.canEdit = d.canEdit;
    this.name = d.name;
    this.url = d.url;
    this.categories = d.categories;
    this.manufacturers = d.manufacturers;
    this.products = d.products;
  }
}

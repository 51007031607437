import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import catalogModule from "./modules/catalog";
import configurationModule from "./modules/configuration";
import customerModule from "./modules/customer";
import billsModule from "./modules/bills";
import settingsModule from "./modules/settings";
import promotionsModule from "./modules/promotions";

import { registerModules } from "@/functions/register-modules";
import { registerGlobals } from "@/functions/register-globals";
import DefaultCard from "@/pages/components/default_card.vue";
import FileUpload from "@/pages/components/file_upload.vue";

import { createI18n } from "vue-i18n";

// Import Theme scss & templete components
import VueFeather from "vue-feather";
import Toaster from "@meforma/vue-toaster";
import SmartTable from "vuejs-smart-table";
import VueSweetalert2 from "vue-sweetalert2";

import "@/theme/assets/scss/app.scss";
import BootstrapVue3 from "bootstrap-vue-3";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import Multiselect from "vue-multiselect";
import chartlist from "vue-chartist";
import VueApexCharts from "vue3-apexcharts";

const i18n = createI18n({
  locale:  localStorage.getItem("currentLanguage") ?? "en",
  globalInjection: true,
  legacy: false, // you must set `false`, to use Composition API
  messages: loadLocaleMessages(),
});

registerModules({
  catalog: catalogModule,
  configuration: configurationModule,
  customer: customerModule,
  billsModule: billsModule,
  settingsModule: settingsModule,
  promotionsModule : promotionsModule
});

const app = createApp(App);
app.component(VueFeather.name, VueFeather);
app.component(DefaultCard.name, DefaultCard);
app.component(FileUpload.name, FileUpload);
app.component(QuillEditor.name, QuillEditor);
app.component("MultiSelect", Multiselect);

app
  .use(router)
  .use(store)
  .use(Toaster, { position: "top-right" })
  .use(i18n)
  .use(SmartTable)
  .use(BootstrapVue3)
  .use(chartlist)
  .use(VueApexCharts)
  .use(VueSweetalert2)
  .mount("#app");

registerGlobals(app);

function loadLocaleMessages() {
  const messages = {};
  //default langs
  loadLocaleMessagesFromModules(
    require.context("@/langs/", true, /[A-Za-z0-9-_,\s]+\.json$/i),
    messages
  );

  //configuration langs
  loadLocaleMessagesFromModules(
    require.context(
      `@/modules/configuration/langs/`,
      true,
      /[A-Za-z0-9-_,\s]+\.json$/i
    ),
    messages
  );

  //catalog langs
  loadLocaleMessagesFromModules(
    require.context(
      `@/modules/catalog/langs/`,
      true,
      /[A-Za-z0-9-_,\s]+\.json$/i
    ),
    messages
  );

  //customers langs
  loadLocaleMessagesFromModules(
    require.context(
      `@/modules/customer/langs/`,
      true,
      /[A-Za-z0-9-_,\s]+\.json$/i
    ),
    messages
  );

  //bills langs
  loadLocaleMessagesFromModules(
    require.context(
      `@/modules/bills/langs/`,
      true,
      /[A-Za-z0-9-_,\s]+\.json$/i
    ),
    messages
  );

  //settings langs
  loadLocaleMessagesFromModules(
    require.context(
      `@/modules/settings/langs/`,
      true,
      /[A-Za-z0-9-_,\s]+\.json$/i
    ),
    messages
  );

    //settings langs
    loadLocaleMessagesFromModules(
      require.context(
        `@/modules/promotions/langs/`,
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i
      ),
      messages
    );

  return messages;
}

function loadLocaleMessagesFromModules(locales, messages) {
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = { ...messages[locale], ...locales(key) };
    }
  });

  return messages;
}

//const catalogModule = () => import('./Module.vue');
const branches = () => import("./views/branchs.vue");
const editingBranch = () => import("./views/editingBranch.vue");
const stores = () => import("./views/stores.vue");
const editingStore = () => import("./views/editingStore.vue");
const currencies = () => import("./views/currencies.vue");
const editingCurrency = () => import("./views/editingCurrency.vue");
const currencyDetails = () => import("./views/currencyDetails.vue");
const taxCategories = () => import("./views/taxCategories.vue");
const editingTaxCategory = () => import("./views/editingTaxCategory.vue");
const productUnits = () => import("./views/productUnits.vue");
const editingProductUnit = () => import("./views/editingProductUnit.vue");
const moduleRoute = {
  path: "/configuration",

  name: "configuration",
  children: [
    {
      name: "branches",
      path: "branches",
      component: branches,
      meta: {
        title: "conf.manage.branches",
        scope: ["branches.get"],
      },
    },
    {
      name: "editingBranch",
      path: "branches/manage/:id?",
      component: editingBranch,
      props: true,
      meta: {
        title: "conf.branches.manage",
        scope: ["branches.post", "branches.put"],
      },
    },
    {
      name: "stores",
      path: "stores",
      component: stores,
      meta: {
        title: "conf.manage.stores",
        scope: ["stores.get"],
      },
    },
    {
      name: "editingStore",
      path: "stores/manage/:id?",
      component: editingStore,
      props: true,
      meta: {
        title: "conf.stores.manage",
        scope: ["stores.post", "stores.put"],
      },
    },
    {
      name: "currencies",
      path: "currencies",
      component: currencies,
      meta: {
        title: "conf.manage.currencies",
        scope: ["currencies.get"],
      },
    },
    {
      name: "editingCurrency",
      path: "currencies/manage/:id?",
      component: editingCurrency,
      props: true,
      meta: {
        title: "conf.currencies.manage",
        scope: ["currencies.post", "currencies.put"],
      },
    },
    {
      name: "currencydetails",
      path: "currencies/currencydetails/:id",
      component: currencyDetails,
      props: true,
      meta: {
        title: "conf.currencyDetails.manage",
        scope: [
          "currencydetails.get",
          "currencydetails.put",
          "currencydetails.post",
          "currencydetails.delete",
        ],
      },
    },

    {
      name: "taxcategories",
      path: "taxcategories",
      component: taxCategories,
      meta: {
        title: "conf.manage.taxCategories",
        scope: ["taxcategories.get"],
      },
    },
    {
      name: "editingTaxCategory",
      path: "taxcategories/manage/:id?",
      component: editingTaxCategory,
      props: true,
      meta: {
        title: "conf.taxCategories.manage",
        scope: ["taxcategories.post", "taxcategories.put"],
      },
    },
    {
      name: "productunits",
      path: "productunits",
      component: productUnits,
      meta: {
        title: "conf.manage.productUnits",
        scope: ["productunits.get"],
      },
    },
    {
      name: "editingProductUnit",
      path: "productunits/manage/:id?",
      component: editingProductUnit,
      props: true,
      meta: {
        title: "conf.productUnits.manage",
        scope: ["productunits.post", "productunits.put"],
      },
    },
  ],
};

export default (router) => {
  router.addRoute(moduleRoute);
};


const invoices = () => import('./views/invoices.vue')

const moduleRoute = {
    path: '/bills',
    name: 'bills',
    children: [
        {
            name: 'sales',
            path: 'sales',
            component: invoices,
            meta: {
                title: "conf.manage.invoices",
                scope: ["invoices.get"],
            },
        }
    ]
};



export default router => {
    router.addRoute(moduleRoute);
};
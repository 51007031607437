export class AppUser {
    id ;
    createdBy;
    createdDate;
    lastModifiedBy;
    lastModifiedDate;
    isDeleted ;
    isPublish ;
    canEdit ;
    fullName;
    mobile;
    email;
    userName;
    password;
    resetCode;
    resetCodeExpired;
    scopes;
    stores = [] | null;
    scopesList = [] | null;
    token = null;
    type;
   constructor(d) {
     this.id = d.id;
     this.createdBy = d.createdBy;
     this.createdDate = d.createdDate;
     this.lastModifiedBy = d.lastModifiedBy;
     this.lastModifiedDate = d.lastModifiedDate;
     this.isDeleted = d.isDeleted;
     this.isPublish = d.isPublish;
     this.canEdit = d.canEdit;
     this.fullName = d.fullName;
     this.mobile = d.mobile;
     this.email = d.email;
     this.userName = d.userName;
     this.password = d.password;
     this.resetCode = d.resetCode;
     this.resetCodeExpired = d.resetCodeExpired;
     this.scopes = d.scopes;
     this.stores = d.stores;
     this.scopesList = d.scopesList;
     this.token = d.token;
     this.type = d.type;
   }
 }
 
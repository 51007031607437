<template>
  <div class="header-wrapper  m-0">
    <Logo />
    <div class="left-header col horizontal-wrapper pl-0">
      <ul class="horizontal-menu">
       <!-- <BonusUI />
        <LevelMenu />-->
      </ul>
    </div>
    <div class="nav-right col-8 pull-right right-header p-0">
      <ul class="nav-menus">
        <li class="language-nav">
          <Language />
        </li>

        <Notifications />

        <li>
          <Mode />
        </li>

        <Message />

        <Profile />
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

//import BonusUI from "./bonusUI";
//import LevelMenu from "./levelMenu";
import Language from "./language";
import Notifications from "./notifications";
import Mode from "./mode";
import Message from "./message";
import Profile from "./profile";
import Logo from "./logo";

export default {
  name: "HeaderIndex",
  components: {
   // BonusUI,
    //LevelMenu,
    Language,
    Notifications,
    Mode,
    Message,
    Profile,
    Logo,
  },
  data() {
    return {
      bookmark: false,
    };
  },
  computed: {
    ...mapState({
      menuItems: (state) => state.menu.searchData,
      megamenuItems: (state) => state.menu.megamenu,
      searchOpen: (state) => state.menu.searchOpen,
    }),
  },
  methods: {
    search_open() {
      this.$store.state.menu.searchOpen = true;
    },
    bookmark_open() {},
  },
};
</script>

const users = () => import("./views/users.vue");
const editingUser = () => import("./views/editingUser.vue");
const editProfile = () => import("./views/editProfile.vue");
const moduleRoute = {
  path: "/settings",
  name: "settings",
  children: [
    {
      name: "users",
      path: "users",
      component: users,
      meta: {
        title: "conf.manage.users",
        scope: ["users.get"],
      },
    },
    {
      name: "editingUser",
      path: "users/manage/:id?",
      component: editingUser,
      props: true,
      meta: {
        title: "conf.users.manage",
        scope: ["users.post", "users.put"],
      },
    },   {
      name: "editProfile",
      path: "users/profile",
      component: editProfile,
      meta: {
        title: "conf.profile.manage",
        scope: ["profile.put"],
      },
    },
  ],
};

export default (router) => {
  router.addRoute(moduleRoute);
};

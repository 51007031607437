import moment from "moment";

export const registerGlobals = (app) => {
  /**
   * @params {date} date to be converted to timeago
   * @returns returns timeAgo
   */
  app.config.globalProperties.$dates = {
    format(date, format = "DD-MM-yyyy") {
      return moment(date).format(format);
    },
  };

  app.config.globalProperties.$defaultLanguage = "default-lang";

  String.prototype.toCamelCase = function () {
    let STR = this.toLowerCase()
      .trim()
      .split(/[ -_]/g)
      .map((word) => word.replace(word[0], word[0].toString().toUpperCase()))
      .join("");
    return STR.replace(STR[0], STR[0].toLowerCase());
  };

  String.prototype.validateEmail = function () {
    let pattern = new RegExp(
      "^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$"
    );
    return pattern.test(this);
  };

  app.config.globalProperties.$access = {
    check(component, scope) {
      let currentUser = component.$store.state.currentUser;
      if (currentUser != null) {
        return (
          currentUser.scopesList != null &&
          currentUser.scopesList.includes(scope)
        );
      }
      return true;
    },
  };

  app.config.globalProperties.$alert = {
    confirm(component, callback) {
      component
        .$swal({
          text: component.$t("alert.delete.row"),
          showCancelButton: true,
          confirmButtonText: component.$t("yes"),
          confirmButtonColor: "#4466f2",
          cancelButtonText: component.$t("no"),
          cancelButtonColor: "#efefef",
          reverseButtons: true,
          icon: "error",
        })
        .then(async (rs) => {
          if (rs.isConfirmed) {
            callback();
          }
        });
    },
  };

  (app.config.globalProperties.$constValue = (key, constant) => {
    var values = constant.filter((f) => f.value == key);
    return values.length > 0 ? values[0] : { text: "" };
  }),
    (app.config.globalProperties.$ColorValue = (key) => {
      var values = [
        { value: 0, color: "badge badge-danger" },
        { value: 1, color: "badge badge-primary" },
        { value: 2, color: "badge badge-secondary" },
        { value: 3, color: "badge badge-success" },
        { value: 4, color: "badge badge-info" },
        { value: 5, color: "badge badge-warning" },
      ].filter((f) => f.value == key);
      return values.length > 0 ? values[0].color : "";
    }),
    (app.config.globalProperties.$constants = {
      AssetTypes(component) {
        return [
          { value: 1, text: component.$t("constant.assettypes.asset") },
          { value: 2, text: component.$t("constant.assettypes.notasset") },
        ];
      },
      CustomerTypes(component) {
        return [
          { value: 1, text: component.$t("constant.customertypes.customer") },
          { value: 2, text: component.$t("constant.customertypes.supplier") },
          { value: 3, text: component.$t("constant.customertypes.employee") },
          { value: 4, text: component.$t("constant.customertypes.company") },
          {
            value: 5,
            text: component.$t("constant.customertypes.organization"),
          },
          { value: 6, text: component.$t("constant.customertypes.owner") },
        ];
      },

      DeliveryDates(component) {
        return [
          { value: 0, text: component.$t("constant.deliverydates.none") },
          {
            value: 1,
            text: component.$t("constant.deliverydates.onetotwodays"),
          },
          {
            value: 2,
            text: component.$t("constant.deliverydates.threetosevendays"),
          },
          {
            value: 3,
            text: component.$t("constant.deliverydates.onetotwoweeks"),
          },
          {
            value: 4,
            text: component.$t("constant.deliverydates.onetotwomonths"),
          },
        ];
      },

      DepreciationStatus(component) {
        return [
          { value: 1, text: component.$t("constant.depreciationstatus.no") },
          { value: 2, text: component.$t("constant.depreciationstatus.yes") },
        ];
      },

      DepreciationWays(component) {
        return [
          {
            value: 1,
            text: component.$t("constant.depreciationways.fixedquantity"),
          },
          {
            value: 2,
            text: component.$t("constant.depreciationways.productionquantity"),
          },
          {
            value: 3,
            text: component.$t("constant.depreciationways.decliningquantity"),
          },
          {
            value: 4,
            text: component.$t("constant.depreciationways.yearsquantity"),
          },
        ];
      },

      InvoiceTypes(component) {
        return [
          { value: 1, text: component.$t("constant.invoicetypes.sales") },
          { value: 2, text: component.$t("constant.invoicetypes.purchase") },
          { value: 3, text: component.$t("constant.invoicetypes.returnsales") },
          {
            value: 4,
            text: component.$t("constant.invoicetypes.returnpurchase"),
          },
          { value: 5, text: component.$t("constant.invoicetypes.salesoffer") },
          {
            value: 6,
            text: component.$t("constant.invoicetypes.purchaseoffer"),
          },
        ];
      },

      ProductGroupTypes(component) {
        return [
          { value: 1, text: component.$t("constant.productgrouptypes.simple") },
          { value: 2, text: component.$t("constant.productgrouptypes.group") },
        ];
      },

      ProductTypes(component) {
        return [
          { value: 1, text: component.$t("constant.producttypes.product") },
          { value: 2, text: component.$t("constant.producttypes.service") },
        ];
      },

      StoreEveluationWays(component) {
        return [
          { value: 1, text: component.$t("constant.storeeveluationways.avg") },
          { value: 2, text: component.$t("constant.storeeveluationways.fifo") },
        ];
      },
      DiscountTypes(component) {
        return [
          { value: 1, text: component.$t("constant.discounttypes.orders") },
          { value: 2, text: component.$t("constant.discounttypes.products") },
          { value: 3, text: component.$t("constant.discounttypes.categories") },
          {
            value: 4,
            text: component.$t("constant.discounttypes.manufacturers"),
          },
          { value: 5, text: component.$t("constant.discounttypes.customers") },
        ];
      },

      DiscountLimitation(component) {
        return [
          {
            value: 1,
            text: component.$t("constant.discountlimitation.unlimited"),
          },
          {
            value: 2,
            text: component.$t("constant.discountlimitation.ntimeonly"),
          },
          {
            value: 3,
            text: component.$t("constant.discountlimitation.ntimepercustomer"),
          },
        ];
      },

      DiscountPercentage(component) {
        return [
          { value: 1, text: component.$t("constant.discountpercentage.yes") },
          { value: 0, text: component.$t("constant.discountpercentage.no") },
        ];
      },

      ValueTypes(component) {
        return [
          { value: 1, text: component.$t("constant.valuetypes.fixed") },
          { value: 2, text: component.$t("constant.valuetypes.parcentage") },
        ];
      },

      UserTypes(component) {
        return [
          { value: 1, text: component.$t("constant.usertypes.admin") },
          { value: 2, text: component.$t("constant.usertypes.cashier") },
          { value: 3, text: component.$t("constant.usertypes.driver") },
        ];
      },
    });
};

import axios from "axios";
import { Store } from "vuex";
const API_URL =
   "https://istore.isoft4is.com/api/";//*/ "https://localhost:5001/api/";

export default class IApiService {
  html = null;
  store = Store;
  SERVICE_URL = "";

  component = null;
  contentType = null;

  constructor(component, contentType) {
    this.initAxios();
    this.component = component;
    this.contentType = contentType;
  }

  setState(store) {
    this.store = store;
  }

  initAxios() {
    this.html = axios.create({
      baseURL: API_URL,
      timeout: 1000 * 60,
      headers: this.authHeader(),
    });
  }

  authHeader() {
    let token = localStorage.getItem("authToken");
    return this.contentType == null
      ? {
          Authorization: "Bearer " + token,
          
        }
      : {
          Authorization: "Bearer " + token,
          Accept: "text/plain",
          "Content-Type ": this.contentType,
        };
  }

  getErrorMessage(rs, failed) {
    if (rs == undefined) return;

    var message = rs.data.title;
    message =
      message == null || message == undefined || message == ""
        ? rs.data
        : message;

    var baseLang = this.component.baseLang ?? "";

    if (rs.data.errors) {
      rs.data.errors.forEach((e) => {
        if (e.errors) {
          e.errors.forEach((x) => {
            var errorMessage = x.includes("[Required]")
              ? `${this.component.$t(
                  `${baseLang}${e.name.toCamelCase()}`
                )} ${this.component.$t("error.required")}`
              : x;
            message = `${message} <br> ${errorMessage}`;
          });
        }
      });
    }

    if (rs != null && rs.data != null) {
      this.component.$toast.error(this.component.$t(message));
    } else {
      this.component.$toast.error(
        `<p><strong>${this.component.$t(
          "alert"
        )}</strong><br>${this.component.$t("error.general.network")}</p>`
      );
    } 
    
    return failed != null ? failed(rs) : null;
  }

  async get(success, failed) {
    return await this.html
      .get(this.SERVICE_URL)
      .then((rs) => success(rs.data))
      .catch(({ response }) => {
        return this.getErrorMessage(response, failed);
      });
  }

  async search(query, success, failed) {
    return await this.html
      .get(`${this.SERVICE_URL}/search/${query}`)
      .then((rs) => success(rs.data))
      .catch(({ response }) => {
        if (response == undefined) return;
        return failed != null ? failed(response) : null;
      });
  }

  async getById(id, success, failed) {
    return await this.html
      .get(`${this.SERVICE_URL}/${id}`)
      .then((rs) => success(rs.data))
      .catch(({ response }) => {
        return this.getErrorMessage(response, failed);
      });
  }

  async post(data, success, failed) {
    return await this.html
      .post(this.SERVICE_URL, data)
      .then((rs) => {
        this.component.$toast.success(
          `<p><strong>${this.component.$t(
            "note"
          )}</strong><br>${this.component.$t("message.general.saved")}</p>`
        );

        return success != null ? success(rs.data) : null;
      })
      .catch(({ response }) => {
        return this.getErrorMessage(response, failed);
      });
  }

  async update(data, success, failed) {
    return await this.html
      .put(this.SERVICE_URL, data)
      .then((rs) => {
        this.component.$toast.success(
          `<p><strong>${this.component.$t(
            "note"
          )}</strong><br>${this.component.$t("message.general.saved")}</p>`
        );

        return success != null ? success(rs.data) : null;
      })
      .catch(({ response }) => {
        return this.getErrorMessage(response, failed);
      });
  }

  async updatePublish(id, status, success, failed) {
    return await this.html
      .put(`${this.SERVICE_URL}/updatestatus/${id}?status=${status}`)
      .then((rs) => {
        this.component.$toast.success(
          `<p><strong>${this.component.$t(
            "note"
          )}</strong><br>${this.component.$t("message.general.saved")}</p>`
        );

        return success != null ? success(rs.data) : null;
      })
      .catch(({ response }) => {
        return this.getErrorMessage(response, failed);
      });
  }

  async delete(id, success, failed) {
    return await this.html
      .delete(`${this.SERVICE_URL}/${id}`)
      .then(() => {
        this.component.$toast.success(
          `<p><strong>${this.component.$t(
            "note"
          )}</strong><br>${this.component.$t("message.general.deleted")}</p>`
        );

        return success ? success() : null;
      })
      .catch(({ response }) => {
        return this.getErrorMessage(response, failed);
      });
  }
}
